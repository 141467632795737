@import "../_sass/variables";

body {
  @include regular-text;
  background: #FFFFFF;
  margin: 0;
  padding: 0;
  color: #000;
}

/* ~~ Element/tag selectors ~~ */
ul, ol, dl { /* Due to variations between browsers, it's best practices to zero padding and margin on lists. For consistency, you can either specify the amounts you want here, or on the list items (LI, DT, DD) they contain. Remember that what you do here will cascade to the .nav list unless you write a more specific selector. */
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0; /* removing the top margin gets around an issue where margins can escape from their containing div. The remaining bottom margin will hold it away from any elements that follow. */
  padding-right: 15px;
  padding-left: 0px; /* adding the padding to the sides of the elements within the divs, instead of the divs themselves, gets rid of any box model math. A nested div with side padding can also be used as an alternate method. */
}

a img { /* this selector removes the default blue border displayed in some browsers around an image when it is surrounded by a link */
  border: none;
}

/* ~~ Styling for your site's links must remain in this order - including the group of selectors that create the hover effect. ~~ */
a:link {
  color: $accent-color;
  text-decoration: underline; /* unless you style your links to look extremely unique, it's best to provide underlines for quick visual identification */
}

a:visited {
  color: $accent-color;
  text-decoration: underline;
}

a:hover, a:active, a:focus { /* this group of selectors will give a keyboard navigator the same hover experience as the person using a mouse. */
  text-decoration: none;
}

img { max-width: 100%; height: auto}

@mixin quote {
  white-space: pre-wrap; //css3
  white-space: moz-pre-wrap; //firefox
  padding: 0.1em 0.5em 0.3em 0.7em;
  border-width: 1px 1px 1px 11px;
  border-style: solid;
  border-color: #ccc;
  margin: 1.7em 0 1.7em 0.3em;
  overflow: auto;
  width: 93%;

  &.quote {
    border-width: 0px;
    @include flavor-text;
    text-align: center;
    font-size: 150%;
  }
}

/* ~~ Site layout ~~ */

/** Default layout **/
div.main-content {
  display: grid;
  grid-template-rows: auto auto 100% auto;
  grid-template-areas:
          "menu"
          "header"
          "content"
          "footer";

  #menu {
    grid-area: menu;
    display: flex;
    justify-content: center;
    background-color: #2e2929;
    padding: 5px;
    border-bottom: 2px solid $accent-color;

    div.container {
      width: $page-width;
      display: flex;
      justify-content: space-between;

      h1 {
        @include flavor-text;
        font-size: 200%;
        font-weight: bold;
        text-transform: uppercase;
        color: #FFF;
        margin: 0;

        a {
          text-decoration: none;
          color: #FFF;
        }

        &::first-letter {
          font-size: 120%;
          color: $accent-color
        }
      }

      /* Hide Hamburger */
      #hamburger_label, #hamburger { display: none; }

      nav#navitems {
        align-self: center;
        display: flex;

        a {
          @include flavor-text;
          display: block;
          color: white;
          text-align: center;
          text-decoration: none;
          font-size: 125%;
          font-weight: bold;
          text-transform: uppercase;
          width: 150px;
          margin-right: 15px;
          background-color: $accent-color;
          padding: 5px 10px;

          &:hover {
            background-color: white;
            color: $accent-color;
          }
        }
      }
    }

  }

  #header {
    grid-area: header;
    display: flex;
    justify-content: center;

    height: 450px;
    background-color: $background-dark;
    padding: 0;

    div.container {
      width: $page-width;
      position: relative;
      height: inherit;
      color: white;
      background: $background-dark url("/media/site/header-yennick-image.jpg") no-repeat left top;

      img.logo {
        position: absolute;
        bottom: -73px;
      }

      div.header-text {
        position: relative;
        left: 550px;
        top: 100px;
        max-width: 600px;

        h2 {
          @include flavor-text;
          font-size: 230%;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        h3 {
          @include regular-text;
          font-size: 170%;
          font-style: italic;
          margin-top: 5px;
        }

        p.caption {
          @include regular-text;
          font-size: 130%;
          color: $accent-color;
        }
      }
    }
  }

  #page-main-area {
    grid-area: content;
    display: flex;
    justify-content: center;

    div.container {
      width: $page-width;
      margin-top: 80px;
    }
  }

  #footer {
    grid-area: footer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: $background-dark;
    color: white;

    div.container {
      width: $page-width;
      position: relative;

      @include regular-text;
      text-align: center;
      font-size: 100%;
    }
  }
}

/** layout for mobile devices **/
@media all and (max-width: 768px) {
  div.main-content {

    #menu {
      div.container {
        width: $mobile-page-width;
        flex-wrap: wrap;

        h1 {
          margin-left: 15px;
        }

        /* Show Hamburger Icon */
        label#hamburger_label {
          display: inline-block;
          color: white;
          background: $accent-color;
          font-style: normal;
          font-size: 150%;
          padding: 10px;
          text-align: right;
        }

        nav#navitems {
          display: none;
          flex-direction: column;
          flex-basis: 100%;

          a {
            width: 100%;
            margin-top: 2px;
            margin-right: 0;
            padding: 15px 0;
            font-size: 170%;
          }
        }

        input#hamburger:checked ~ nav#navitems {
          display: flex;
          flex-direction: column;
        }
      }
    }

    #header {
      height: 160px;

      div.container {
        width: $mobile-page-width;
        display: flex;
        flex-direction: column;
        height: inherit;
        background: $background-dark;
        justify-content: center;

        img.logo {
          width: 75px;
          height: 73px;
          bottom: -36px;
        }

        div.header-text {
          align-self: center;
          position: static;

          h2 {
            font-size: 160%;
            text-align: center;
          }

          h3 {
            margin-block-end: auto;
            font-size: 130%;
            text-align: center;
          }

          p.caption {
            display: none;
          }
        }

        img.logo {
          left: 15px;
        }
      }
    }

    #page-main-area {
      div.container {
        width: $mobile-page-width;
        margin: 40px 10px 0 10px;
      }
    }

    #footer {
      div.container {
        width: $mobile-page-width;
      }
    }
  }
}

.articles-preview-component {
  @include highlight-box;
  margin: 20px 0;

  .articles-list {
    display: flex;
    flex-wrap: wrap;

    .article-preview {
      padding: 15px 25px;
      margin-right: 10px;
      margin-bottom: 5px;
    }

    .coming_soon {
      align-self: center;
      font-size: 150%;
    }
  }

  @media all and (max-width: 768px) {
    .articles-list {
      flex-direction: column;

      .article-preview {
        margin-bottom: 10px;
        margin-right: 0px;
      }
    }
  }
}

.subscription-form {
  margin: 50px 10px;
}

#landing-page {
  p.intro {
    font-size: 150%;
    margin: 30px 0;
  }
}

#post {

  color: #454646;
  line-height: 1.5;
  font-size: 19px;

  .other-articles {
    display: flex;
    flex-direction: column;
    border-width: 1px 1px 1px 11px;
    border-style: solid;
    border-color: $accent-color;
    padding: 0.1em 0.5em 0.3em 0.7em;

    .title {
      display: block;
      font-size: 120%;
    }

    .article {
    }
  }

  h1 {
    @include flavor-text;
    font-size: 250%;
  }

  h2 {
    @include flavor-text;
    font-size: 200%;
    color: $digitalbuff-brown;
  }

  h3 {
    @include flavor-text;
    font-size: 150%;
  }

  strong {
    color: $digitalbuff-brown;
  }

  pre {
    white-space: pre-wrap; //css3
    white-space: moz-pre-wrap; //firefox
    padding: 0.1em 0.5em 0.3em 0.7em;
    border-left: 11px solid #ccc;
    margin: 1.7em 0 1.7em 0.3em;
    overflow: auto;
    width: 93%;
    font-family: "Courier New", Courier, monospace, sans-serif;
  }

  blockquote {
    @include quote;
  }

  ul, ol {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  img {
    margin: 5px;
  }

  .language-plaintext.highlighter-rouge {
    background-color: #EEE;
    padding: 2px 3px;
  }

  .share-buttons {
    display: flex;
    align-items: baseline;
    margin: 25px 0;

    .share-btn {
      display: inline-block;
      color: #ffffff;
      border: none;
      padding: 5px 15px;
      outline: none;
      text-align: center;
      font-size: 100%;
      margin: 0 5px;
    }

    .share-btn:focus,
    .share-btn:hover {
      text-decoration: none;
      opacity: 0.8;
    }

    .share-btn:active {
      color: #e2e2e2;
    }

    .share-btn.twitter { background: #55acee; }
    .share-btn.hackernews { background: #ff6600; }
  }
}

#page-main-area #hire-me {
  margin-top: 100px;
  font-size: 125%;

  &>h2 {
    margin-top: 50px;
  }

  blockquote {
    @include quote;
  }

  ul, ol {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .fully-booked-section {
    @include highlight-box;
    margin-top: 30px;
  }

  .knowledge-sample {
    font-size: 80%;
  }

  .opensource-contribution-panel {
    @include highlight-box;
    font-size: 80%;
    margin-bottom: 10px;
  }

  .hire-me-form {
    display: flex;
    flex-direction: column;
    margin: 50px 10px;

    input, textarea {
        background: #ffffff;
        font-size: 15px;
        padding: 12px;
        border: 1px solid #e3e3e3;
        -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        line-height: 1.4;
        margin: 10px 0;
        border-color: rgb(227, 227, 227); 
        border-radius: 4px; 
        font-weight: 400;
        font-size: 15px;
    }

    button {
        background-color: rgb(243, 120, 70);
        color: #FFFFFF;
        padding: 12px 24px;
        border-radius: 4px;
        font-size: 15px;
        border: 0px;
    }
  }
}
