$page-width: 1024px;
$mobile-page-width: 100%;

$digitalbuff-brown: #2e2929;
$digitalbuff-orange: #f37846;

$accent-color: $digitalbuff-orange;
$text-grey: #545454;
$background-dark: $digitalbuff-brown;

@mixin regular-text {
  font-family: 'Droid Sans', sans-serif;
}

@mixin flavor-text {
  font-family: 'Homenaje', sans-serif;
}

@mixin drop-shadow ($x: 0, $y: 1px, $blur: 1px, $spread: 0px, $alpha: 0.20) {
  -webkit-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
  -moz-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
  box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin highlight-box {
  border: 2px solid $accent-color;
  border-radius: 5px;
  background-color: $background-dark;
  color: #FFF;
  padding: 10px;

  .section-title {
    @include flavor-text;
    font-size: 200%;
    display: block;
    margin-bottom: 5px;
    font-weight: normal;
  }
}
